import { useRef, useState } from 'react';

export default () => {
  const scrollRef = useRef();
  const [currentItem, setCurrentItem] = useState(0);

  const scrollTo = (direction) => {
    const { current } = scrollRef;
    const childrenCount = current.childElementCount;
    const increment = current.firstChild.offsetWidth;
    const leftPos = Number(current.style.left.replace('px', ''));

    if (direction === 'right' && currentItem <= childrenCount - 2) {
      current.style.left = `${leftPos + -increment}px`;
      setCurrentItem(currentItem + 1);
    } else if (direction === 'left' && currentItem > 0) {
      current.style.left = `${leftPos + increment}px`;
      setCurrentItem(currentItem - 1);
    }
  };

  return {
    scrollTo,
    scrollRef,
  };
};
