import React from 'react';
import { object } from 'prop-types';
import ScrollingArrows from '../common/ScrollingArrows';
import { getMessages } from '../../locales';

const AboutTeam = ({ team, images }) => {
  const messages = getMessages();
  
  return <section className="container about-team">
    <h3 className="about-team-title">{messages['about.team.title']}</h3>
    <ScrollingArrows className="featured-carousel-content">
      {team.map(({ quote, name, src }, id) => (
        <div key={id} className="about-team-member">
          <p className="about-team-member-quote">{`"${quote}"`}</p>
          <div className="about-team-member-author">
            <img
              width="180"
              height="180"
              src={images.nodes[id].src.childImageSharp.fluid.src}
              alt={`${name} avatar`}
            />
            <div className="author-desc">
              <h4>{name}</h4>
            </div>
          </div>
        </div>
      ))}
    </ScrollingArrows>
  </section>;
};

AboutTeam.propTypes = {
  team: object.isRequired,
};

export default AboutTeam;
