import React from 'react';
import { number, string } from 'prop-types';

const LocationIcon = ({ width = 23, height = 33, color = '#25466D', className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.5045 0C5.1656 0 0 5.16804 0 11.5069C0 16.0257 2.72581 20.9806 5.36472 24.9389C8.00362 28.8973 10.6463 31.8244 10.6463 31.8244C10.7542 31.9439 10.8859 32.0393 11.0329 32.1046C11.18 32.17 11.3391 32.2039 11.5 32.2039C11.6609 32.2039 11.82 32.17 11.9671 32.1046C12.1141 32.0393 12.2458 31.9439 12.3537 31.8244C12.3537 31.8244 14.9964 28.8973 17.6353 24.9389C20.2742 20.9806 23 16.0257 23 11.5069C23 5.16804 17.8434 0 11.5045 0ZM11.5045 2.30049C16.6001 2.30049 20.6996 6.41129 20.6996   11.5069C20.6996 15.0398 18.2606 19.8593 15.7235 23.665C13.6134 26.8302 12.1549 28.4294 11.5045 29.1758C10.8555 28.4312 9.39468 26.8321 7.28326 23.665C4.74615 19.8593 2.30045 15.0398 2.30045 11.5069C2.30045 6.41129 6.40886 2.30049 11.5045 2.30049ZM11.5045 6.90384C8.97711 6.90384 6.90135 8.97954 6.90135 11.5069C6.90135 14.0343 8.97711 16.1076 11.5045 16.1076C14.0319 16.1076 16.1054 14.0343 16.1054 11.5069C16.1054 8.97954 14.0319 6.90384 11.5045 6.90384ZM11.5045 9.20406C12.7886 9.20406 13.8049 10.2228 13.8049 11.5069C13.8049 12.791 12.7886 13.8072 11.5045 13.8072C10.2204 13.8072 9.20404 12.791 9.20404 11.5069C9.20404 10.2228 10.2204 9.20406 11.5045 9.20406Z"
      fill={color}
    />
  </svg>
);

LocationIcon.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string,
};

export default LocationIcon;
