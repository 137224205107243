import React from 'react';
import { node, string, bool } from 'prop-types';
import cn from 'classnames';

import useBlockScroll from '../../../hooks/UseBlockScroll';
import ArrowIcon from '../../Icons/ArrowIcon';
import Button from '../Button/Button';

const ScrollingArrows = ({ className, children, disabled = false }) => {
  const { scrollRef, scrollTo } = useBlockScroll();

  return (
    <div className="arrows-container">
      <div ref={scrollRef} className={cn('arrows-content-container', className)}>
        {children}
      </div>
      {!disabled && (
        <>
          <Button
            className={cn('arrow left')}
            onClick={() => scrollTo('left')}
            ariaLabel="Move Left"
          >
            <ArrowIcon width={14} height={26} type="left" color="white" />
          </Button>
          <Button className={cn('arrow')} onClick={() => scrollTo('right')} ariaLabel="Move Right">
            <ArrowIcon width={14} height={26} type="right" color="white" />
          </Button>
        </>
      )}
    </div>
  );
};

ScrollingArrows.propTypes = {
  className: string,
  children: node,
  disabled: bool,
};

export default ScrollingArrows;
