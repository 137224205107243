import React from 'react';
import LocationIcon from '../Icons/LocationIcon';
import { getMessages } from '../../locales';

const AboutCareers = () => {
  const messages = getMessages();

  return <section className="container about-careers">
    <div className="about-careers-header">
      <h3>{messages['about.careers.title']}</h3>
    </div>
    <div className="row">
      {messages['about.careers'].map(({ title, city }, id) => (
        <div key={id} className="about-career col-md-6 col-sm-12">
          <div className="about-career-wrapper">
            <p>{title}</p>
            <span>
              <LocationIcon width={18} height={24} color="#4f4f4f" />
              {city}
            </span>
          </div>
        </div>
      ))}
    </div>
  </section>;
};


export default AboutCareers;
